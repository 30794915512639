import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as S3 from 'aws-sdk/clients/s3';

@Injectable({
    providedIn: 'root'
})
export class UserService {


      apiUrl: any = 'https://service.ip-ihrb.com/api/zuul-service'; 
    //  apiUrl: any = 'https://service-uat.ip-ihrb.com/api/zuul-service';
    //  apiUrl: any = 'http://localhost:8762/api/zuul-service';
    //  apiUrl: any = 'http://192.168.1.106:8762/api/zuul-service';

    person: any;
    currentPerson: any;
    application: any;
    isAdditionalLicence: any;
    id: any;
    longName: any;
    role: any;
    licenceType: any;
    dateOfBirth: any;
    apprenticeApplication: any;
    constructor(private http: HttpClient) {
    }

    getPersonList() {
        return this.person;
    }

    setPersonList(personList) {
        if (personList.role === 'User') {
            this.currentPerson = personList.data.find(x => x.isAdditionalLicence === false);
        } else {
            this.currentPerson = personList;
        }
        this.person = personList;
    }

    getCurrentLicenceType() {
        return this.application;
    }

    setCurrentLicenceType() {
        let currentApplicationId = localStorage.getItem('currentApplicationId');
        const applicationPerson = JSON.parse(localStorage.getItem('person'));
        let currentApplicationData = applicationPerson.data.find(x => x.licensingApplicationId === currentApplicationId);
        this.application = currentApplicationData;
    }

    setCurrentApprenticeApplication() {
        let apprenticeApplicationId = localStorage.getItem('apprenticeApplicationId');
        const applicationPerson = JSON.parse(localStorage.getItem('person'));
        let currentApplicationData = applicationPerson.apprenticeLicensingApplicationList.find(x => x.licensingApplicationId === apprenticeApplicationId);
        this.apprenticeApplication = currentApplicationData;
    }

    getCurrentApprentice() {
        return this.apprenticeApplication;
    }

    awsAuth() {
        const bucket = new S3(
            {
                accessKeyId: 'AKIA3GLQQPIJNMQKNY5X',
                secretAccessKey: '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',
                region: 'eu-west-1'
            }
        );
        return bucket;
    }

    doLoginService(data) {
        return this.http.post(this.apiUrl + '/auth-service/auth', data);
    }

    forgotPasswordService(data) {
        return this.http.post(this.apiUrl + '/auth-service/auth/forgotpassword', data);
    }

    resetPasswordGetTokenService(data) {
        return this.http.get(this.apiUrl + '/auth-service/auth/resetpassword', { headers: { 'Authorization': data } });
    }

    resetPasswordService(data) {
        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
        };
        return this.http.post(this.apiUrl + '/auth-service/auth/resetpassword', data, options);
    }

    doRegisterService(data) {
        return this.http.post(this.apiUrl + '/auth-service/auth/registrationrequest', data);
    }

    getRegistrationService() {
        return this.http.get(this.apiUrl + '/auth-service/auth/registration');
    }

    doTaskService(token, link, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(link, data, options);
    }


    changePasswordService(token, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
        };
        return this.http.post(this.apiUrl + '/auth-service/auth/changepassword', data, options);
    }

    switchUserService(token, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/auth-service/auth/switchuser', data, options);
    }


    getUserProfileDataService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getprofile', options);
    }

    getUserConcussionListService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getconcussiondates', options);
    }

    getJockeyAgentDataService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/jockeyagentlist', options);
    }

    getUserDashboardDataService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/dashboarddata', options);
    }

    updateUserDataService(token, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.put(this.apiUrl + '/users-service/updateperson', data, options);
    }

    getUserVerifyService(emailToken) {
        return this.http.get(this.apiUrl + '/auth-service/auth/createpassword?token=' + emailToken);
    }

    createPasswordService(data) {
        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
        };
        return this.http.post(this.apiUrl + '/auth-service/auth/createpassword', data, options);
    }

    getNotificationService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/notification-service/unreadnotifications', options);
    }

    getReadNotificationService(token, pageId, itemsPerPage, key, direction, filterLetter) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };

        if (itemsPerPage) {
            if (key) {
                return this.http.get(this.apiUrl + '/notification-service/readnotifications?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/notification-service/readnotifications?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&searchBy=' + filterLetter, options);
            }
        } else {
            if (key) {
                return this.http.get(this.apiUrl + '/notification-service/readnotifications?pageNo='
                    + pageId + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/notification-service/readnotifications?pageNo='
                    + pageId + '&searchBy=' + filterLetter, options);
            }
        }
    }

    postConfirmJockeyAgentService(token, id, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/confirmjockeyagent/' + id, data, options);
    }

    getGeneralTaskService(token, pageId, itemsPerPage, key, direction, filterLetter, licenceTypeStatusForGeneralTask, applicantStatusForGeneralTask) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        if (itemsPerPage) {
            if (key) {
                return this.http.get(this.apiUrl + '/notification-service/generaltasks?pageNo='
                    + pageId + '&applicantStatus=' + applicantStatusForGeneralTask + '&licenceTypeStatus=' + licenceTypeStatusForGeneralTask + '&pageSize=' + itemsPerPage + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/notification-service/generaltasks?pageNo='
                    + pageId + '&applicantStatus=' + applicantStatusForGeneralTask + '&licenceTypeStatus=' + licenceTypeStatusForGeneralTask + '&pageSize=' + itemsPerPage + '&searchBy=' + filterLetter, options);
            }
        } else {
            if (key) {
                return this.http.get(this.apiUrl + '/notification-service/generaltasks?pageNo='
                    + pageId + '&applicantStatus=' + applicantStatusForGeneralTask + '&licenceTypeStatus=' + licenceTypeStatusForGeneralTask + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/notification-service/generaltasks?pageNo=' + pageId + '&applicantStatus=' + applicantStatusForGeneralTask + '&licenceTypeStatus=' + licenceTypeStatusForGeneralTask + '&searchBy=' + filterLetter, options);
            }
        }
    }

    getPersonListService(token, pageId, itemsPerPage, key, direction, filterLetter) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        if (itemsPerPage) {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/personlist?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/personlist?pageNo=' + pageId + '&pageSize=' + itemsPerPage + '&searchBy=' + filterLetter, options);
            }
        } else {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/personlist?pageNo='
                    + pageId + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/personlist?pageNo=' + pageId + '&searchBy=' + filterLetter, options);
            }
        }
    }


    getPersonListDetailsService(token, pageId, itemsPerPage, key, direction, filterLetter, licenceType, expiryDate) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        if (itemsPerPage) {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/renewlicensedetails?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter + '&licenceType=' + licenceType + '&expiryDate=' +expiryDate, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/renewlicensedetails?pageNo=' + pageId + '&pageSize=' + itemsPerPage + '&searchBy=' + filterLetter + '&licenceType=' +  + '&expiryDate=' +expiryDate, options);
            }
        } else {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/renewlicensedetails?pageNo='
                    + pageId + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter + '&licenceType=' + licenceType+ '&expiryDate=' + expiryDate, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/renewlicensedetails?pageNo=' + pageId + '&searchBy=' + filterLetter + '&licenceType=' + licenceType + '&expiryDate=' +expiryDate, options);
            }
        }
    }

    getSubmittedLicensingApplicationListService(token, pageId, itemsPerPage, key, direction, filterLetter) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        if (itemsPerPage) {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/submittedlicensingapplicationlist?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/submittedlicensingapplicationlist?pageNo=' + pageId + '&pageSize=' + itemsPerPage + '&searchBy=' + filterLetter, options);
            }
        } else {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/submittedlicensingapplicationlist?pageNo='
                    + pageId + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/submittedlicensingapplicationlist?pageNo=' + pageId + '&searchBy=' + filterLetter, options);
            }
        }
    }




    getRoleListToAssignService(token) {
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/rolelist', options);
    }

    getNotificationCountService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/notification-service/countnotifications', options);
    }

    getLicenceTypeListService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/licencetypelist', options);
    }

    getUserLicenceHistoryService(token, pageId, itemsPerPage, key, direction, filterLetter) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        if (itemsPerPage) {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/licencehistorydata?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/licencehistorydata?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&searchBy=' + filterLetter, options);
            }
        } else {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/licencehistorydata?pageNo='
                    + pageId + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/licencehistorydata?pageNo=' + pageId + '&searchBy=' + filterLetter, options);
            }
        }
    }

    getLicenceHistoryDataByIdService(token, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getlicencehistory?id=' + id, options);
    }

    addUpdateLicenceService(token, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/licencehistoryinfo', data, options);
    }

    deleteLicenceService(token, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.delete(this.apiUrl + '/users-service/deletelicencehistory/' + id, options);
    }

    viewUserEducationHistoryService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/educationhistorydata', options);
    }

    getUserEducationHistoryService(token, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/educationhistorydata?id=' + id, options);
    }

    updateEducationService(token, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/educationhistoryinfo', data, options);
    }

    getUserMessageListService(token, data, pageId, itemsPerPage) {
        const options = {
            params: data,
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };

        if (itemsPerPage) {
            return this.http.get(this.apiUrl + '/users-service/usermessagelist?pageNo=' + pageId + '&pageSize=' + itemsPerPage, options);
        } else {
            return this.http.get(this.apiUrl + '/users-service/usermessagelist?pageNo=' + pageId, options);
        }
    }

    getDaywiseInboxService(token, date, senderId, day) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/usermessagelistbydate?date='
            + date + '&day=' + day + '&senderId=' + senderId, options);
    }

    getUserChatService(token, data, pageSize) {
        const options = {
            params: data,
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/userchat?pageSize=' + pageSize, options);
    }

    postMessageService(token, data) {
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/sendmessage', data, options);
    }

    postNewMessageService(token, data) {
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/sendnewmessage', data, options);
    }


    getUnassignedMessageService(token, pageId, itemsPerPage) {
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        if (itemsPerPage) {
            return this.http.get(this.apiUrl + '/users-service/unassignmessagelist?pageNo='
                + pageId + '&pageSize=' + itemsPerPage, options);
        } else {
            return this.http.get(this.apiUrl + '/users-service/unassignmessagelist?pageNo=' + pageId, options);
        }
    }

    getStaffListToAssignService(token) {
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/stafflist', options);
    }

    getLicensingDeptListToAssignService(token) {
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getlicensingdepartmentlist', options);
    }

    toAssignMessageService(token, data, threadData) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
                .set('threadId', threadData)
        };
        return this.http.post(this.apiUrl + '/users-service/assignmessage', data, options);
    }

    deleteFileService(token, fileUrl) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.delete(this.apiUrl + '/users-service/deletefile?url=' + fileUrl, options);
    }

    assignedRoleService(token, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/assignrole', data, options);
    }

    getConsentService(token, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/consentdata?currentApplicationId=' + id, options);
    }

    getDataProtectionService(token, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getdataprotection?currentApplicationId=' + id, options);
    }

    postDataProtectionService(token, data, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.post(this.apiUrl + '/users-service/dataprotection?currentApplicationId=' + id, data, options);

    }

    changeConsentService(token, data, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/consentinfo?currentApplicationId=' + id, data, options);
    }

    getUserDataService(token, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/persondata?currentApplicationId=' + id, options);
    }

    updateUserDataService2(token, data, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.put(this.apiUrl + '/users-service/updateprofiledetails?currentApplicationId=' + id, data, options);
    }

    getExperienceService(token, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getexperience?currentApplicationId=' + id, options);
    }

    postExperienceService(token, data, id) {
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/experience?currentApplicationId=' + id, data, options);
    }

    getFitAndProperPersonService(token, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getfitandproperperson?currentApplicationId=' + id, options);
    }

    postFitAndProperPersonService(token, data, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/fitandproperperson?currentApplicationId=' + id, data, options);
    }

    getMedicalServices(token, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getmedicalquestions?currentApplicationId=' + id, options);
    }

    postMedicalService(token, data, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/medicalquestions?currentApplicationId=' + id, data, options);
    }

    getConcussionService(token, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getconcussion?currentApplicationId=' + id, options);
    }

    postConcussionService(token, data, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/concussion?currentApplicationId=' + id, data, options);
    }

    getFixturesService(token, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getfixtures?currentApplicationId=' + id, options);
    }

    postFixturesService(token, data, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/fixtures?currentApplicationId=' + id, data, options);
    }

    getFiscalService(token, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getfiscal?currentApplicationId=' + id, options);
    }

    postFiscalService(token, data, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/fiscal?currentApplicationId=' + id, data, options);
    }

    getInsuranceService(token, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getinsurance?currentApplicationId=' + id, options);
    }

    postInsuranceService(token, data, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/insurance?currentApplicationId=' + id, data, options);
    }

    getPersonnelService(token, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getpersonnel?currentApplicationId=' + id, options);
    }

    postPersonnelService(token, data, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/personnel?currentApplicationId=' + id, data, options);
    }

    getFacilitiesService(token, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getfacilities?currentApplicationId=' + id, options);
    }

    postFacilitiesService(token, data, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/facilities?currentApplicationId=' + id, data, options);
    }

    getDeclarationService(token, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getdeclaration?currentApplicationId=' + id, options);
    }

    postDeclarationService(token, data, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/declaration?currentApplicationId=' + id, data, options);
    }

    getSummaryService(token, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getapplicationsummary?currentApplicationId=' + id, options);
    }

    postFileService(token, data, id) {
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/applicationsummarydocumentupload?currentApplicationId=' + id, data, options);
    }

    postSummaryService(token, data, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/applicationsummary?currentApplicationId=' + id, data, options);
    }

    viewApplicationDataService(token, registrationRequestId, approvalType, licenceType) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/notification-service/view/'
            + registrationRequestId + '?approvalType=' + approvalType + '&licenceType=' + licenceType, options);
    }

    postRemoveTypeService(token, data) {
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer ' + token).set('Content-Type', 'application/json')
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/remove', data, options);
    }

    getIHRBMedicalExamFormService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getMedicalReport', options);
    }

    getGPMedicalExamFormService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getGPMedicalReport', options);
    }

    getMedicationFormService(token, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/getMedicationReport', data, options);
    }

    postUserDetailsByLicensingDeptService(token, data, requestType) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        if (requestType === 'Update profile request') {
            return this.http.post(this.apiUrl + '/users-service/updateprofilebylicensingdepartment', data, options);
        } else {
            return this.http.post(this.apiUrl + '/auth-service/auth/updateregistrationrequestbylicensingdepartment', data, options);
        }
    }

    getLicensingApplicationListService(token, pageId, itemsPerPage, key, direction, filterLetter, summaryStatus, licenceTypeStatusForApplicationTask) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        if (itemsPerPage) {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/getlicensingapplicationlist?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&licenceTypeStatus=' + licenceTypeStatusForApplicationTask + '&sortBy=' + key + '&direction=' + direction + '&summaryStatus=' + summaryStatus + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/getlicensingapplicationlist?pageNo='
                    + pageId + '&licenceTypeStatus=' + licenceTypeStatusForApplicationTask + '&pageSize=' + itemsPerPage + '&summaryStatus=' + summaryStatus + '&searchBy=' + filterLetter, options);
            }
        } else {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/getlicensingapplicationlist?pageNo='
                    + pageId + '&licenceTypeStatus=' + licenceTypeStatusForApplicationTask + '&sortBy=' + key + '&summaryStatus=' + summaryStatus + '&direction=' + direction + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/getlicensingapplicationlist?pageNo=' + pageId + '&licenceTypeStatus=' + licenceTypeStatusForApplicationTask + '&searchBy=' + filterLetter + '&summaryStatus=' + summaryStatus, options);
            }
        }
    }

    getOtherTasksListService(token, pageId, itemsPerPage, key, direction, filterLetter) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        if (itemsPerPage) {
            if (key) {
                return this.http.get(this.apiUrl + '/notification-service/otherlicensingdepartmenttasks?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/notification-service/otherlicensingdepartmenttasks?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&searchBy=' + filterLetter, options);
            }
        } else {
            if (key) {
                return this.http.get(this.apiUrl + '/notification-service/otherlicensingdepartmenttasks?pageNo='
                    + pageId + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/notification-service/otherlicensingdepartmenttasks?pageNo=' + pageId + '&searchBy=' + filterLetter, options);
            }
        }
    }

    getWaitingOnOtherTasksListService(token, pageId, itemsPerPage, key, direction, filterLetter) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        if (itemsPerPage) {
            if (key) {
                return this.http.get(this.apiUrl + '/notification-service/waitingonotherstasks?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/notification-service/waitingonotherstasks?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&searchBy=' + filterLetter, options);
            }
        } else {
            if (key) {
                return this.http.get(this.apiUrl + '/notification-service/waitingonotherstasks?pageNo='
                    + pageId + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/notification-service/waitingonotherstasks?pageNo=' + pageId + '&searchBy=' + filterLetter, options);
            }
        }
    }

    viewLicensingApplicationDataService(token, applicationId) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/viewlicensingapplication/' + applicationId, options);
    }

    viewCompletelicensingapplicationService(token, applicationId) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/viewcompletelicensingapplication/' + applicationId, options);
    }

    viewMedicalDataService(token, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/viewmedicalapplication?currentApplicationId=' + id, options);
    }

    postClaimOrAssignService(token, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/claimorassign', data, options);
    }

    getLicensingDeptTaskByUserService(token, id, pageId, itemsPerPage, key, direction, filterLetter) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        if (itemsPerPage) {
            if (key) {
                return this.http.get(this.apiUrl + '/notification-service/licensingapplicationtasks?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&sortBy=' + key + '&direction=' + direction + '&id=' + id + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/notification-service/licensingapplicationtasks?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&id=' + id + '&searchBy=' + filterLetter, options);
            }
        } else {
            if (key) {
                return this.http.get(this.apiUrl + '/notification-service/licensingapplicationtasks?pageNo='
                    + pageId + '&sortBy=' + key + '&direction=' + direction + '&id=' + id + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/notification-service/licensingapplicationtasks?pageNo='
                    + pageId + '&id=' + id + '&searchBy=' + filterLetter, options);
            }
        }
    }

    getViewApplicantSummaryDetailsService(token, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/viewdocument?id=' + id, options);
    }

    getTaskByPersonService(token, id, pageId, itemsPerPage, key, direction, filterLetter, generalPersonStatus, taskByPerson) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        if (itemsPerPage) {
            if (key) {
                return this.http.get(this.apiUrl + '/notification-service/tasksbyperson?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&generalPersonStatus=' + generalPersonStatus + '&sortBy=' + key + '&direction=' + direction + '&id=' + id + '&searchBy=' + filterLetter + '&taskByPerson=' + taskByPerson, options);
            } else {
                return this.http.get(this.apiUrl + '/notification-service/tasksbyperson?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&generalPersonStatus=' + generalPersonStatus + '&id=' + id + '&searchBy=' + filterLetter + '&taskByPerson=' + taskByPerson, options);
            }
        } else {
            if (key) {
                return this.http.get(this.apiUrl + '/notification-service/tasksbyperson?pageNo='
                    + pageId + '&sortBy=' + key + '&generalPersonStatus=' + generalPersonStatus + '&direction=' + direction + '&id=' + id + '&searchBy=' + filterLetter + '&taskByPerson=' + taskByPerson, options);
            } else {
                return this.http.get(this.apiUrl + '/notification-service/tasksbyperson?pageNo='
                    + pageId + '&generalPersonStatus=' + generalPersonStatus + '&id=' + id + '&searchBy=' + filterLetter + '&taskByPerson=' + taskByPerson, options);
            }
        }
    }

    postCommitteeReportService(token, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/reviewlicensingapplication', data, options);
    }

    getCommitteeReportService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/committeereportlist', options);
    }

    downloadReportService(token, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/generatereport', data, options);
    }

    downloadReportByIdService(token, data, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/generatereportbyid?id=' + id, data, options);
    }

    postProceedIssueLicenceService(token, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/proceedissuelicence', data, options);
    }

    changeRenewalApplicationService(token, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/requestrenewlicensingapplicationbylicensingdepartment', data, options);
    }

    changeMedicalQuestionnaireService(token, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/requestmedicalquestionnairebyseniormedicalofficer', data, options);
    }

    prepareForCommitteeMeetingService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/prepareforcommitteemeeting', token, options);
    }

    publishDetailsInRacingCalendarService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/publishdetailsinracingcalendar', token, options);
    }

    getJockeyAgentValetApplicationService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getlicensingapplication', options);
    }

    postJockeyValetFileDataService(token, data) {
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/fileupload', data, options);
    }

    deleteRiderService(token, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/deleterider/' + id, 'delete-rider', options);
    }

    postJockeyAgentValetApplicationService(token, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/licensingapplication', data, options);
    }

    getAddRiderService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/riderlist', options);
    }

    getAddAgentService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/agentlist', options);
    }

    postSuspendAccountPersonService(token, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/suspenduseraccountbylicensingonlineadmin', data, options);
    }

    suspendLicenceListService(token, id, pageId, itemsPerPage, key, direction, filterLetter) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        if (itemsPerPage) {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/getlicencehistorybyid?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&sortBy=' + key + '&direction=' + direction + '&id=' + id + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/getlicencehistorybyid?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&id=' + id + '&searchBy=' + filterLetter, options);
            }
        } else {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/getlicencehistorybyid?pageNo='
                    + pageId + '&sortBy=' + key + '&direction=' + direction + '&id=' + id + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/getlicencehistorybyid?pageNo='
                    + pageId + '&id=' + id + '&searchBy=' + filterLetter, options);
            }
        }
    }

    emergencyLicenceApplicationListService(token, pageId, itemsPerPage, key, direction, filterLetter) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        if (itemsPerPage) {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/emergencylicencehistory?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/emergencylicencehistory?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&searchBy=' + filterLetter, options);
            }
        } else {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/emergencylicencehistory?pageNo='
                    + pageId + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/emergencylicencehistory?pageNo='
                    + pageId + '&searchBy=' + filterLetter, options);
            }
        }
    }

    postEmergencyLicenceService(token, data, licenceType) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/requestemergencylicencebylicensingdepartment?licenceType=' + licenceType, data, options);
    }

    postSuspendLicencePersonService(token, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/suspendlicencebylicensingdepartment', data, options);
    }

    postLiftSuspendLicencePersonService(token, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/liftsuspendedlicencebylicensingdepartment', data, options);
    }

    getJockeyListByPersonService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/jockeylistbyperson', options);
    }

    postImportQualifiedRidersFileService(token, data) {
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/auth-service/auth/importusers', data, options);
    }

    postRiderReportService(token, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/riderlistreport?age=' + data.age, data, options);
    }

    getCurrentLicencesByCategoryService(token, subLicenceType) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/licencesByCategory?category=' + subLicenceType, options);
    }

    getQRJockeyListService(token, pageId, itemsPerPage, key, direction, filterLetter) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        if (itemsPerPage) {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/qrjockeylist?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/qrjockeylist?pageNo=' + pageId + '&pageSize=' + itemsPerPage + '&searchBy=' + filterLetter, options);
            }
        } else {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/qrjockeylist?pageNo='
                    + pageId + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/qrjockeylist?pageNo=' + pageId + '&searchBy=' + filterLetter, options);
            }
        }
    }

    postUserInformationService(token, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/updateuserinformation', data, options);
    }

    getExpiredLicencesService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/expiredlicences', options);
    }

    getUserDataByLOAService(token, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/userdata?id=' + id, options);
    }

    getLicencesWithRestrictionsService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/licenceswithrestriction', options);
    }

    getQRMasterListService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/issuelicencereport', options);
    }

    getClaimingListWeeklyService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/claiminglistreport', options);
    }

    getCreateJournalVoucherService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/journalvoucherexcel', options);
    }


    getJournalVoucherService(token, journalVoucherNumber) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/generatejournalvoucher?journalVoucher=' + journalVoucherNumber, options);
    }

    getJournalVoucherByLicensingApplicationIdService(token, licensingApplicationId) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/generatejournalvoucherbyemail?licensingApplicationId=' + licensingApplicationId, options);
    }

    searchJournalVoucherService(token, filterLetter) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/searchjournalvoucher?searchBy=' + filterLetter, options);
    }

    searchJournalVoucherByEmailService(token, filterLetter) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/searchjournalvoucherbyemail?searchBy=' + filterLetter, options);
    }

    getCalenderListService(token, licenceType) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/calenderlistreport?licenceType=' + licenceType, options);
    }

    getPrintLicencesService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/printlicences', options);
    }

    postExperienceFileDataService(token, data) {
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/experiencefileupload', data, options);
    }

    getLicenceHistoryReportService(token, id, licenceType) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/licencehistoryreport?&id=' + id + '&licenceType=' + licenceType, options);

    }

    getLicenceHistoryExcelService(token, id, licenceType) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/licencehistoryexcel?&id=' + id + '&licenceType=' + licenceType, options);

    }

    getExpiredLicenceService(token, email) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/expiredlicencereport?email=' + email, options);
    }

    getUserInformationService(token, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/userinformation?id=' + id, options);
    }

    getRequestListService(token, pageId, itemsPerPage, key, direction, filterLetter) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        if (itemsPerPage) {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/medicalquestionnairelist?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/medicalquestionnairelist?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&searchBy=' + filterLetter, options);
            }
        } else {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/medicalquestionnairelist?pageNo='
                    + pageId + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/medicalquestionnairelist?pageNo=' + pageId + '&searchBy=' + filterLetter, options);
            }
        }
    }

    getCompletedApplicationListService(token, pageId, itemsPerPage, key, direction, filterLetter) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        if (itemsPerPage) {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/completedlicensingapplicationpersonlist?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/completedlicensingapplicationpersonlist?pageNo=' + pageId + '&pageSize=' + itemsPerPage + '&searchBy=' + filterLetter, options);
            }
        } else {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/completedlicensingapplicationpersonlist?pageNo='
                    + pageId + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/completedlicensingapplicationpersonlist?pageNo=' + pageId + '&searchBy=' + filterLetter, options);
            }
        }
    }

    getCompletedApplicationByUserService(token, id, pageId, itemsPerPage, key, direction, filterLetter) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        if (itemsPerPage) {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/completedlicensingapplicationlist?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&sortBy=' + key + '&direction=' + direction + '&id=' + id + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/completedlicensingapplicationlist?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&id=' + id + '&searchBy=' + filterLetter, options);
            }
        } else {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/completedlicensingapplicationlist?pageNo='
                    + pageId + '&sortBy=' + key + '&direction=' + direction + '&id=' + id + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/completedlicensingapplicationlist?pageNo='
                    + pageId + '&id=' + id + '&searchBy=' + filterLetter, options);
            }
        }
    }

    additionalLicenceService(token, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/addadditionallicence', data, options);
    }

    getFutureDevelopmentService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getfuturedevelopment', options);
    }

    postFutureDevelopmentService(token, data) {
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/futuredevelopment', data, options);
    }

    getLicenceFeesService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getlicencefees', options);
    }

    postLicenceFeesService(token, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/updatelicencefees', data, options);
    }

    selectLicenceFeesDataForUserLicensingApplicationService(token, applicationId) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getlicencefeesforuserlicensingapplication?currentApplicationId=' + applicationId, options);
    }


    doSelectLicenceFeesDataForUserLicensingApplicationTaskService(token, link, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(link, data, options);
    }


    postConfirmLicenceFeesService(token, applicationId, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/confirmlicencefees?currentApplicationId=' + applicationId, data, options);
    }

    viewLicenceFeesDataForUserLicensingApplicationService(token, applicationId) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/viewlicencefeesforuserlicensingapplication?currentApplicationId=' + applicationId, options);
    }

    getLicenceFeeService(token, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getlicencefee?currentApplicationId=' + id, options);
    }

    postOptionalFeeService(token, id, optionalFee) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/addoptionalfee?currentApplicationId=' + id, optionalFee, options);
    }

    postPrincipalYardService(token, data, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.post(this.apiUrl + '/users-service/principalyard?currentApplicationId=' + id, data, options);

    }


    getPrincipalYardService(token, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.get(this.apiUrl + '/users-service/getprincipalyard?currentApplicationId=' + id, options);


    }


    postAdditionalYardService(token, data, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.post(this.apiUrl + '/users-service/additionalyard?currentApplicationId=' + id, data, options);

    }

    getAdditionalYardService(token, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.get(this.apiUrl + '/users-service/getadditionalyard?currentApplicationId=' + id, options);

    }

    postTrainerFitAndProperPersonService(token, data, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.post(this.apiUrl + '/users-service/trainerfitandproperperson?currentApplicationId=' + id, data, options);


    }


    getSbsAgreementService(token, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };
        return this.http.get(this.apiUrl + '/users-service/getsbsagreement?currentApplicationId=' + id, options);
    }

    postSbsAgreementService(token, data, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.post(this.apiUrl + '/users-service/sbsagreement?currentApplicationId=' + id, data, options);

    }



    getAuthorisedRepresentativeService(token, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.get(this.apiUrl + '/users-service/getauthorisedrepresentative?currentApplicationId=' + id, options);

    }


    getEducationService(token, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.get(this.apiUrl + '/users-service/geteducation?currentApplicationId=' + id, options);

    }

    getDetailsService(token, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.get(this.apiUrl + '/users-service/getdetails?currentApplicationId=' + id, options);


    }

    getEmploymentConditionService(token, id) {
        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.get(this.apiUrl + '/users-service/getemploymentconditions?currentApplicationId=' + id, options);

    }

    getNextStepsService(token, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.get(this.apiUrl + '/users-service/getnextsteps?currentApplicationId=' + id, options);

    }

    postEmploymentConditionService(token, data, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.post(this.apiUrl + '/users-service/employmentconditions?currentApplicationId=' + id, data, options);

    }

    postNextStepsService(token, data, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.post(this.apiUrl + '/users-service/nextsteps?currentApplicationId=' + id, data, options);

    }

    postDetailsService(token, data, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.post(this.apiUrl + '/users-service/details?currentApplicationId=' + id, data, options);

    }

    postEducationService(token, data, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.post(this.apiUrl + '/users-service/education?currentApplicationId=' + id, data, options);

    }


    postAuthorisedRepresentativeService(token, data, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.post(this.apiUrl + '/users-service/authorisedrepresentative?currentApplicationId=' + id, data, options);


    }

    postMessagingServiceService(token, data, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.post(this.apiUrl + '/users-service/messageservice?currentApplicationId=' + id, data, options);


    }


    postAssistantTrainerService(token, data, id) {

        const options = {

            headers: new HttpHeaders()

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.post(this.apiUrl + '/users-service/assistanttrainer?currentApplicationId=' + id, data, options);


    }



    postNewEmployeeCardDataService(token, data, id) {

        const options = {

            headers: new HttpHeaders()

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.post(this.apiUrl + '/users-service/employeeupload?currentApplicationId=' + id, data, options);


    }


    getAssistantTrainerService(token, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.get(this.apiUrl + '/users-service/getassistanttrainer?currentApplicationId=' + id, options);

    }


    removeAssistantTrainerService(token, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.get(this.apiUrl + '/users-service/removeassistanttrainer?assistantTrainerId=' + id, options);


    }


    getEmployeeCardService(token, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.get(this.apiUrl + '/users-service/getemployeecards?currentApplicationId=' + id, options);

    }

    getMessagingService(token, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.get(this.apiUrl + '/users-service/getmessageservice?currentApplicationId=' + id, options);


    }

    // removeEmployeeService(token, id) {

    //     const options = {

    //         headers: new HttpHeaders().set('Content-Type', 'application/json')

    //             .set('Authorization', 'Bearer ' + token)

    //             .set('token', token)

    //     };

    //     return this.http.post(this.apiUrl + '/users-service/removeemployeecard?employeeCardId=' + id, options);


    // }




    removeEmployeeService(token, data) {
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer ' + token).set('Content-Type', 'application/json')
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/removeemployeecard', data, options);
    }

    removeParticularSubscriberMessagingService(token, data, id) {
        const options = {

            headers: new HttpHeaders()

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };
        return this.http.post(this.apiUrl + '/users-service/removemessagingservice?currentApplicationId=' + id, data, options);

    }

    postEmployeeCardDataService(token, data, id) {

        const options = {

            headers: new HttpHeaders()

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.post(this.apiUrl + '/users-service/employeeCard?currentApplicationId=' + id, data, options);

    }

    postUpdateEmployeeCardDataService(token, data, id) {
        const options = {

            headers: new HttpHeaders()

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.post(this.apiUrl + '/users-service/updateemployeeCard?currentApplicationId=' + id, data, options);


    }

    downloadSBSAgreementFormService(token, data, id) {

        const options = {

            headers: new HttpHeaders()

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.post(this.apiUrl + '/users-service/generatesbsform', data, options);
    }


    downloadEmployeePDFService(token, id) {

        const options = {

            headers: new HttpHeaders()

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };
        return this.http.get(this.apiUrl + '/users-service/generateemployeecardsform?licensingApplicationId=' + id, options);

    }

    downloadPrincipalYardPDFService(token, id, type) {

        const options = {

            headers: new HttpHeaders()

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };
        return this.http.get(this.apiUrl + '/users-service/generatereportbylicensingdepartment?currentApplicationId=' + id + '&yardType=' + type, options);

    }

    downloadAssistantTrainerPDFService(token, id) {

        const options = {

            headers: new HttpHeaders()

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };
        return this.http.get(this.apiUrl + '/users-service/generateassistanttrainer?licensingApplicationId=' + id, options);

    }

    downloadAuthorisedRepresentativePDFService(token, id) {

        const options = {

            headers: new HttpHeaders()

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.get(this.apiUrl + '/users-service/generateauthrep?licensingApplicationId=' + id, options);


    }

    getCreateMessageServiceService(token, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };

        return this.http.get(this.apiUrl + '/users-service/messagingserviceexcel?licensingApplicationId=' + id, options);

    }

    removeNewAuthRepresentativeServices(token, data) {

        const options = {

            headers: new HttpHeaders()

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };
        return this.http.post(this.apiUrl + '/users-service/removeauthrep', data, options);

    }


    postRejectLicensingApplicationService(token, data) {
        const options = {

            headers: new HttpHeaders()

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.post(this.apiUrl + '/users-service/applicationrejectbylicensingdepartment', data, options);


    }


    getApplicationProgressService(token, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.get(this.apiUrl + '/users-service/getapplicationprogress?currentApplicationId=' + id, options);

    }

    postApplicaintChargeFeesService(token, data) {
        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };
        return this.http.post(this.apiUrl + '/users-service/chargefees', data, options);
    }

    selectFeesDataForPersonService(token, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getpersonfees?personId=' + id, options);
    }

    getTrainerService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/gettrainerlist', options);
    }

    doSelectFeesForUserTaskService(token, link, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(link, data, options);
    }

    getAuthorisedRepsCalenderListService(token, type) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/calenderauthreport?type=' + type, options);
    }

    getPersonFeesService(token, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.get(this.apiUrl + '/users-service/getapplicationprogress?personId=' + id, options);

    }

    viewPersonFeesDataService(token, personId) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/viewfeesforapplicaintId?personId=' + personId, options);
    }

    getPersonFeeService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getpersonfeeslist', options);
    }


    postConfirmPersonFeeService(token, personId, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/confirmfeesrequest?personId=' + personId, data, options);
    }

    postSendToAccountsService(token, data) {

        const options = {

            headers: new HttpHeaders()

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };
        return this.http.post(this.apiUrl + '/users-service/proceedsendfeesbylicensingdepartmenttoaccounts', data, options);

    }


    getGeneralTaskListForAccountsService(token, pageId, itemsPerPage, key, direction, filterLetter, summaryStatus, licenceTypeStatusForApplicationTask) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        if (itemsPerPage) {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/generaltasksforaccounts?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&licenceTypeStatus=' + licenceTypeStatusForApplicationTask + '&sortBy=' + key + '&direction=' + direction + '&summaryStatus=' + summaryStatus + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/generaltasksforaccounts?pageNo='
                    + pageId + '&licenceTypeStatus=' + licenceTypeStatusForApplicationTask + '&pageSize=' + itemsPerPage + '&summaryStatus=' + summaryStatus + '&searchBy=' + filterLetter, options);
            }
        } else {

            if (key) {
                return this.http.get(this.apiUrl + '/users-service/generaltasksforaccounts?pageNo='
                    + pageId + '&licenceTypeStatus=' + licenceTypeStatusForApplicationTask + '&sortBy=' + key + '&summaryStatus=' + summaryStatus + '&direction=' + direction + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/generaltasksforaccounts?pageNo=' + pageId + '&licenceTypeStatus=' + licenceTypeStatusForApplicationTask + '&searchBy=' + filterLetter + '&summaryStatus=' + summaryStatus, options);
            }
        }
    }


    getAccountTaskByUserService(token, id, pageId, itemsPerPage, key, direction, filterLetter) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        if (itemsPerPage) {
            if (key) {
                return this.http.get(this.apiUrl + '/notification-service/accountfeestasks?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&sortBy=' + key + '&direction=' + direction + '&id=' + id + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/notification-service/accountfeestasks?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&id=' + id + '&searchBy=' + filterLetter, options);
            }
        } else {
            if (key) {
                return this.http.get(this.apiUrl + '/notification-service/accountfeestasks?pageNo='
                    + pageId + '&sortBy=' + key + '&direction=' + direction + '&id=' + id + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/notification-service/accountfeestasks?pageNo='
                    + pageId + '&id=' + id + '&searchBy=' + filterLetter, options);
            }
        }
    }


    postClaimService(token, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/claim', data, options);
    }

    getPersonLicenceTypeDataService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getpersonapplicationlist', options);
    }

    postLicenceTypeService(token, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/applylicence', data, options);
    }

    switchUserAccountService(token, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/switchuseraccount', data, options);
    }

    getUserApplicationForLicensingDepartmentService(token, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/getuserapplicationforlicensingdepartment', data, options);
    }

    getsuspendLicenceListService(token, id, licenceType, pageId, itemsPerPage, key, direction, filterLetter) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        if (itemsPerPage) {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/getlicencehistorybyid?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&sortBy=' + key + '&direction=' + direction + '&id=' + id + '&licenceType=' + licenceType + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/getlicencehistorybyid?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&id=' + id + '&licenceType=' + licenceType + '&searchBy=' + filterLetter, options);
            }
        } else {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/getlicencehistorybyid?pageNo='
                    + pageId + '&sortBy=' + key + '&direction=' + direction + '&id=' + id + '&licenceType=' + licenceType + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/getlicencehistorybyid?pageNo='
                    + pageId + '&id=' + id + '&licenceType=' + licenceType + '&searchBy=' + filterLetter, options);
            }
        }
    }

    getsuspendLicenceService(token, id, licenceType) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getsuspendedlicence?&id=' + id + '&licenceType=' + licenceType, options);
    }


    postUpdateUserApplicationTypeService(token, data) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/assignlicenceapplicationtype', data, options);
    }

    getEmployeeCardListService(token) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/employeecardcsvreport', options);
    }

    postRelinquishLicenseDataService(token, data, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.post(this.apiUrl + '/users-service/relinquishlicense?currentApplicationId=' + id, data, options);

    }

    showPersonAuditTrailHistoryService(token, personId) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getevent?personId=' + personId, options);
    }



    getPersonAuditTrailHistoryService(token, personId, pageId, itemsPerPage, key, direction, filterLetter) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        if (itemsPerPage) {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/getevent?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter + '&personId=' + personId, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/getevent?pageNo=' + pageId + '&pageSize=' + itemsPerPage + '&searchBy=' + filterLetter + '&personId=' + personId, options);
            }
        } else {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/getevent?pageNo='
                    + pageId + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter + '&personId=' + personId, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/getevent?pageNo=' + pageId + '&searchBy=' + filterLetter + '&personId=' + personId, options);
            }
        }
    }

    getAllAmateurRiderAndProfessionalJockeyPersonListService(token, pageId, itemsPerPage, key, direction, filterLetter) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        if (itemsPerPage) {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/getallriderandjockeylist?pageNo='
                    + pageId + '&pageSize=' + itemsPerPage + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/getallriderandjockeylist?pageNo=' + pageId + '&pageSize=' + itemsPerPage + '&searchBy=' + filterLetter, options);
            }
        } else {
            if (key) {
                return this.http.get(this.apiUrl + '/users-service/getallriderandjockeylist?pageNo='
                    + pageId + '&sortBy=' + key + '&direction=' + direction + '&searchBy=' + filterLetter, options);
            } else {
                return this.http.get(this.apiUrl + '/users-service/getallriderandjockeylist?pageNo=' + pageId + '&searchBy=' + filterLetter, options);
            }
        }
    }

    getMedicalQuestionServices(token, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.get(this.apiUrl + '/users-service/getallmedicalquestions?personId=' + id, options);
    }


    postMedicalQuestionsRequestService(token, data) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.post(this.apiUrl + '/users-service/sendmedicalquestionsrequest', data, options);

    }

    postMedicalQuestionService(token, data, id) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/medicaldetails?personId=' + id, data, options);
    }

    postHorsesService(token, data, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.post(this.apiUrl + '/users-service/horses?currentApplicationId=' + id, data, options);


    }
    getHorsesService(token, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.get(this.apiUrl + '/users-service/gethorses?currentApplicationId=' + id, options);


    }
    removeHorsesService(token, data) {
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer ' + token).set('Content-Type', 'application/json')
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/removehorses', data, options);
    }
    postEmploymentService(token, data, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.post(this.apiUrl + '/users-service/employment?currentApplicationId=' + id, data, options);


    }
    getEmploymentService(token, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.get(this.apiUrl + '/users-service/getemployment?currentApplicationId=' + id, options);


    }
    removeEmployerService(token, data) {
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer ' + token).set('Content-Type', 'application/json')
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/removeemployer', data, options);
    }

    postUploadProspectiveOwnersService(token, data, id) {

        const options = {

            headers: new HttpHeaders()

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.post(this.apiUrl + '/users-service/uploadprospectiveowners?currentApplicationId=' + id, data, options);


    }
    removeProspectiveOwnerDocumentService(token, data) {
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer ' + token).set('Content-Type', 'application/json')
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/removeprospectiveowners', data, options);
    }
    postProspectiveOwnersService(token, data, id) {

        const options = {

            headers: new HttpHeaders()

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.post(this.apiUrl + '/users-service/prospectiveowners?currentApplicationId=' + id, data, options);


    }
    getProspectiveOwnersService(token, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.get(this.apiUrl + '/users-service/getprospectiveowners?currentApplicationId=' + id, options);


    }
    postCorporateService(token, data, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.post(this.apiUrl + '/users-service/corporate?currentApplicationId=' + id, data, options);


    }
    getCorporateService(token, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.get(this.apiUrl + '/users-service/getcorporate?currentApplicationId=' + id, options);


    }


    getEmployeeTrainersService(token, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.get(this.apiUrl + '/users-service/getemployeetrainers?currentApplicationId=' + id, options);


    }

    getPortfolioService(token, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.get(this.apiUrl + '/users-service/getportfolio?currentApplicationId=' + id, options);


    }

    getPostInterviewService(token, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.get(this.apiUrl + '/users-service/getpostinterview?currentApplicationId=' + id, options);


    }

    postTrainerPersonnelService(token, data, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.post(this.apiUrl + '/users-service/trainerpersonnel?currentApplicationId=' + id, data, options);


    }
    getTrainerPersonnelService(token, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.get(this.apiUrl + '/users-service/gettrainerpersonnel?currentApplicationId=' + id, options);


    }
    postTrainerExperienceService(token, data, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.post(this.apiUrl + '/users-service/trainerexperience?currentApplicationId=' + id, data, options);


    }
    getTrainerExperienceService(token, id) {

        const options = {

            headers: new HttpHeaders().set('Content-Type', 'application/json')

                .set('Authorization', 'Bearer ' + token)

                .set('token', token)

        };

        return this.http.get(this.apiUrl + '/users-service/gettrainerexperience?currentApplicationId=' + id, options);


    }
    removePreviousLicencesService(token, data) {
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer ' + token).set('Content-Type', 'application/json')
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/removepreviouslicences', data, options);
    }
    removeRaceHorsesService(token, data) {
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer ' + token).set('Content-Type', 'application/json')
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/removeracehorses', data, options);
    }
    removeAdditionalYardService(token, data) {
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer ' + token).set('Content-Type', 'application/json')
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/removeadditionalyard', data, options);
    }

    submitProcessToRenew(token, data){
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer ' + token)
                .set('token', token)
        };
        return this.http.post(this.apiUrl + '/users-service/renewlicensingapplicationforlicencetype', data, options);
    }
}
