import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import {ToastrService} from "ngx-toastr";

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private toastr: ToastrService, private router: Router) {}

    canActivate() {
        if (localStorage.getItem('token')) {
            return true;
        } else {
            localStorage.removeItem('token');
            localStorage.removeItem('person');
            localStorage.removeItem('isSwitchUser');
            localStorage.removeItem('licenceTypeList');
            localStorage.removeItem('receiverId');
            localStorage.removeItem('tab');
            localStorage.removeItem('threadId');
            localStorage.removeItem('LicenceDepartmentPerson');
            localStorage.removeItem('LicenceDepartmentToken');
            localStorage.removeItem('rolelist');
            localStorage.removeItem('userAssignList');
            localStorage.removeItem('currentApplicationId');

            localStorage.removeItem('concussion');
            localStorage.removeItem('data-protection');
            localStorage.removeItem('declaration');
            localStorage.removeItem('experience');
            localStorage.removeItem('facilities');
            localStorage.removeItem('fiscal');
            localStorage.removeItem('fitAndProperPerson');
            localStorage.removeItem('fixtures');
            localStorage.removeItem('insurance');
            localStorage.removeItem('medicalQuestions');
            localStorage.removeItem('personnel');
            localStorage.removeItem('details');
            localStorage.removeItem('tab');
            this.router.navigate(['/login']);
            return false;
        }
    }
}
