import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LanguageTranslationModule} from './shared/modules/language-translation/language-translation.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthGuard} from './shared';
import {UserService} from './shared/services/user/user.service';
import {ConfigService} from './shared/services/config/config.service';
import {FormModule} from './layout/form/form.module';
import {ReactiveFormsModule} from '@angular/forms';
import {ToastrModule} from 'ngx-toastr';
import {SidebarModule} from 'ng-sidebar';
import {NgxSpinnerModule} from 'ngx-spinner';

import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';

const cookieConfig:NgcCookieConsentConfig = {
    cookie: {
        domain: 'ip-ihrb.com'
    },
    palette: {
        popup: {
            "background": "#13202d",
            "text": "#ffffff",
            "link": "#ffffff"
        },
        button: {
            "background": "#6fb95d",
            "text": "#000000",
            "border": "transparent"
        }
    },
    position: 'top',
    theme: 'edgeless',
    type: 'opt-out',
    layout: 'my-custom-layout',
    layouts: {
        "my-custom-layout": '{{messagelink}}{{compliance}}'
    },
    elements:{
        messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}} 
      <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank">{{privacyPolicyLink}}</a> and our 
      <a aria-label="learn more about our terms of service" tabindex="2" class="cc-link" href="{{tosHref}}" target="_blank">{{tosLink}}</a>
    </span>
    `,
    },
    content:{
        message: 'By using our site, you acknowledge that you have read and understand our ',

        privacyPolicyLink: 'Privacy Policy',
        privacyPolicyHref: 'https://'+ 'uat.ip-ihrb.com' + '/static-page/privacy-policy',
        tosLink: 'Terms and Conditions',
        tosHref: 'https://'+ 'uat.ip-ihrb.com' + '/static-page/termsandconditions',
    }
};


@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        LanguageTranslationModule,
        AppRoutingModule,
        FormModule,
        ReactiveFormsModule,
        NgxSpinnerModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
        }),
        SidebarModule.forRoot(),
        NgcCookieConsentModule.forRoot(cookieConfig)
    ],
    declarations: [AppComponent],
    providers: [AuthGuard, UserService, ConfigService],
    bootstrap: [AppComponent],
})
export class AppModule {}
