import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    constructor() {
    }
}

@Pipe({
    name: 'niceDateFormatPipe',
})
export class niceDateFormatPipe implements PipeTransform {
    transform(value: any) {
        let time = new Date(value);
        var _value = Number(time);

        var dif = Math.floor(((Date.now() - _value) / 1000) / 86400);
        if (dif < 1) {
            var datePipe = new DatePipe('en-US');
            value = datePipe.transform(value, 'HH:mm');
            return value;

        } else {
            var datePipe = new DatePipe('en-US');
            value = datePipe.transform(value, 'dd/MM/yyyy');
            return value;
        }
    }
}

@Pipe({
    name: 'timeDateFormatPipe',
})
export class timeDateFormatPipe implements PipeTransform {
    transform(value: any) {
        let time = new Date(value);
        var _value = Number(time);

        var dif = Math.floor(((Date.now() - _value) / 1000) / 86400);
        if (dif < 1) {
            return timeDateFormat(value);
        } else {
            var datePipe = new DatePipe('en-US');
            value = datePipe.transform(value, 'dd/MM/yyyy');
            return value;
        }
    }
}

function timeDateFormat(time: string) {
    var date = new Date(time),
        diff = (((new Date()).getTime() - date.getTime()) / 1000),
        daydiff = Math.floor(diff / 86400);

    if (isNaN(daydiff) || daydiff < 0 || daydiff >= 31)
        return '';

    return daydiff == 0 && (
        diff < 60 && "Just now" ||
        diff < 120 && "1 minute ago" ||
        diff < 3600 && Math.floor(diff / 60) + " minutes ago" ||
        diff < 7200 && "1 hour ago" ||
        diff < 86400 && Math.floor(diff / 3600) + " hours ago") ||
        daydiff == 1 && "Yesterday";
}

@Pipe({
    name: 'dateFormatPipe',
})
export class dateFormatPipe implements PipeTransform {
    transformCurrentdate(value: any) {
        let time = new Date(value);
        var datePipe = new DatePipe('en-US');
        value = datePipe.transform(value, 'yyyy-MM-dd 00:00:00');
        return value;
    }

    transform(value: any, ...args: any[]): any {
    }
}
