import { trigger } from '@angular/animations';

export function routerTransition() {

    return noTransition();
}

export function noTransition() {
    return trigger('routerTransition', []);
}
