import { Component, OnInit, Input } from '@angular/core';
declare var $: any;

@Component({
    selector: 'app-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
    @Input() heading: string;
    @Input() icon: string;
    arrow: boolean = false;
    constructor() {}

    ngOnInit() {}

    listGroup() {
        if (this.arrow === false) {
            $("body").addClass("push-right");
            $("footer").addClass("footerPush-right");
        } else {
            $("body").removeClass("push-right");
            $("footer").removeClass("footerPush-right");
        }
        this.arrow = !this.arrow;
    }
}
