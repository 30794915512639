import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from "../../services/user/user.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder } from "@angular/forms";
declare var $: any;
@Component({
    selector: 'app-stat',
    templateUrl: './stat.component.html',
    styleUrls: ['./stat.component.scss']
})
export class StatComponent implements OnInit {
    @Input() bgClass: string;
    @Input() icon: string;
    @Input() count: number;
    @Input() label: string;
    @Input() apprenticeName: string;
    @Input() applicationId: string;
    @Input() data: number;
    @Output() event: EventEmitter<any> = new EventEmitter();
    token = localStorage.getItem('token');
    person = JSON.parse(localStorage.getItem('person'));
    additionalData: any;
    additionalLicence: any;
    isAdditionalLicenceData: any;
    constructor(private router: Router,
        private toastr: ToastrService,
        private userService: UserService,
        private spinner: NgxSpinnerService) {
    }

    ngOnInit() {
        this.isAdditionalLicenceData = this.person.data.find(x => x.isAdditionalLicence === false);
    }

    toLicenceApplication() {
        this.router.navigate(['/profile/my-profile']);
    }

    toApprenticeLicenceApplication(){
        localStorage.setItem('apprenticeApplicationId', this.applicationId);
        this.router.navigate(['/apprentice-application']);
    }

    toApplication(licensingApplicationId) {
        localStorage.setItem('currentApplicationId', licensingApplicationId);
        if (this.isAdditionalLicenceData.licenceType === 'Jockey Valet') {
            this.router.navigate(['/jockey-valet']);
        } else if (this.isAdditionalLicenceData.licenceType === 'Jockey Agent') {
            this.router.navigate(['/jockey-agent']);
        } else {
            this.router.navigate(['/application-form']);
        }
    }

    additionalLicenceModel() {
        $('#addAdditionalLicenceModal').modal('show');
    }

    selectAdditionalLicence(licence) {
        this.additionalLicence = licence;
    }

    addAdditionalLicence() {
        const additionalLicenceData = { applicationId: this.isAdditionalLicenceData.licensingApplicationId, licenceType: this.additionalLicence };
        this.spinner.show();
        this.userService.additionalLicenceService(this.token, additionalLicenceData).subscribe((data: any) => {
            this.spinner.hide();
            if (data.status === true) {
                this.toastr.success(data.message);
                this.getDashboardData();
            } else {
                this.toastr.error(data.message);
            }
            this.noAdditionalLicence();
        }, error => {
            if (error.status === 200) {
                return;
            } else {
                this.toastr.error(error.message);
            }
            this.spinner.hide();
        });
    }

    noAdditionalLicence() {
        this.additionalLicence = '';
        $('#addAdditionalLicenceModal').modal('hide');
    }

    getDashboardData() {
        this.userService.getUserDashboardDataService(this.token).subscribe((data: any) => {
            this.person = data;
            this.userService.setPersonList(data);
            this.userService.getPersonList();
        }, error => {
            if (error.status === 200) {
                return;
            } else if (error.status === 401) {
                localStorage.clear();
                this.toastr.success(error.error.message);
                this.router.navigate(['/signout']);
            } else {
                this.toastr.error(error.message);
            }
        });
    }
}
